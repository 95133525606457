import React, { useState, useEffect, useRef } from 'react';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import NewsComp from '../../../components/common/NewsComp';
import { languageText } from "../../../languageText/marketNewsCommon"

const OvernightMarket = () => {

    const api = new API();
    const [OvernightMarket, setOvernightMarket] = useState([]);
    const [selectDate,setSelectDate] = useState("")
    const param = "overnightmarkets";
    const dataslider = useRef();
    const [siteLang , setSiteLang] = useState("")
    
    useEffect(() => {
        window.scrollTo(0, 0);
        var siteLanguage = $.cookie('applang');
        setSiteLang(siteLanguage)
        if(siteLanguage == "eng"){
        document.querySelector("body").classList.add("eng")
        }else{
        document.querySelector("body").classList.remove("eng")
        }
    }, [])
    
    useEffect(() => {
        getDayHighlight()
    }, [selectDate])

    useEffect(()=>{
        if(selectDate !== ""){
            getDayHighlight()
        }
    },[selectDate])

    const getDayHighlight = async () => {
        let OvernightMarket = await api.get(config.marketOvernightMarket, true, { sp: param, start: "0",... selectDate && {startdate: selectDate} , qid: Date.now() })
        let OvernightMarkett = get(OvernightMarket, 'newsList', [])
        setOvernightMarket(OvernightMarkett)
        dataslider.current.slickGoTo(0)
    };

    const linkData = [
        {
            name:(siteLang == "eng") ? languageText.subscribeSidebarTitleEn : languageText.subscribeSidebarTitle,    
            link:"/marketnews/subscribe",
            short:(siteLang == "eng") ? languageText.subscribeSidebarDescriptionEn : languageText.subscribeSidebarDescription,
            imgClass:"section-qlink-subscribe"
        },
        {
            name:(siteLang == "eng")? languageText.foreignIndexUpdateSidebarTitleEn : languageText.foreignIndexUpdateSidebarTitle,
            link:"/marketnews/todayhighlight",
            short:(siteLang == "eng") ? languageText.foreignIndexUpdateSidebarDescriptionEn : languageText.foreignIndexUpdateSidebarDescription,
            imgClass:"section-qlink-todayhighlight"
        },
        // {
        //     name:(siteLang == "eng") ? languageText.dailyCommentarySidebarTitleEn : languageText.dailyCommentarySidebarTitle,
        //     link:"/marketnews/dailycommentary",
        //     short:(siteLang == "eng") ? languageText.dailyCommentarySidebarDescriptionEn : languageText.dailyCommentarySidebarDescription,
        //     imgClass:"section-qlink-marketcommentary"
        // },
    ];

    return (
        <NewsComp 
            param={param}
            data={OvernightMarket}
            section={(siteLang == "eng") ? languageText.firstNavigationEn : languageText.firstNavigation}
            breadcrumb={(siteLang == "eng") ? languageText.overNightMarketNavigationEn : languageText.overNightMarketNavigation}
            title={(siteLang == "eng") ? languageText.overNightMarketPageTitleEn : languageText.overNightMarketPageTitle}
            subtitle={(siteLang == "eng") ? languageText.overNightMarketPageDescriptionEn : languageText.overNightMarketPageDescription}
            dataIndex={3}
            setSelectDate={setSelectDate}
            dataslider={dataslider}
            newsFeeds={linkData}
        />   
    );
}
export default OvernightMarket;
