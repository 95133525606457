import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { languageText } from "../../../languageText/common"

const WarrantCalculatorSideBars = (props) => {

    const [siteLang , setSiteLang] = useState("")

    useEffect(() => {
        var siteLanguage = $.cookie('applang');
        setSiteLang(siteLanguage)
        if(siteLanguage == "eng"){
          document.querySelector("body").classList.add("eng")
        }else{
          document.querySelector("body").classList.remove("eng")
        }
    }, [])

    return(
        <div className="section qlink-section">
            <div className="page-header">
            <h2 className="small">
                {siteLang == "eng" ? languageText.sidebarCalculatorTitleEn : languageText.sidebarCalculatorTitle}
            </h2>
            </div>
            <p className="section-p">
                {siteLang == "eng" ? languageText.sidebarCalculatorDescriptionEn : languageText.sidebarCalculatorDescription}
            </p>
            <div className="section-qlink-img section-qlink-dwcalculator">
            <Link to="/tools/dwcalculator" className="section-qlink-achor">
                <img src="/img/qlink-achor.png" alt="indexdw" />
            </Link>
            </div>
        </div>
    )
};
export default WarrantCalculatorSideBars;